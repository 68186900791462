<template>
  <div class="z-0">
    <div class="flex flex-col justify-start w-full text-yellow-50 p-3 mb-28">
      <h4>All Bet Transactions</h4>

      <div class="bg-gray-800 py-2 px-3 rounded max-w-5xl w-full flex-none">
        <div class="text-base font-medium">
          <span class="mr-4 font-bold">Transactions</span>
          <i class="fad fa-wallet mr-2 z-0"></i>
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-400"
          >
            {{ address.slice(0, 10) }}...{{ address.slice(-8) }}
          </span>
        </div>
      </div>

      <div class="flex-glow max-w-5xl w-full">
        <div v-if="!ready" class="flex flex-col">
          <loader class="h-40"></loader>
          <p class="text-center">Loading...</p>
        </div>
        <div v-else>
          <div v-if="filteredItems.length == 0">
            <div class="flex flex-col">
              <chameleon class="h-40"></chameleon>
              <p class="text-center">You don't have a transaction</p>
            </div>
          </div>
          <div v-else class="w-full items-center">
            <div
              v-for="t in filteredItems"
              v-bind:key="t.id"
              class="flex w-full bg-gray-500 bg-opacity-10 my-1 hover:bg-gray-500 hover:bg-opacity-25 p-2 rounded-md"
            >
              <div class="flex-grow flex flex-col sm:flex-row">
                <span class="hidden md:block mr-3 text-gray-500 text-sm">{{
                  t.createdAt
                }}</span>
                <div class="sm:mr-2 text-gray-500 text-sm sm:text-base">
                  <a
                    class="text-baseline text-blue text-blue-400 mr-3"
                    :href="'https://testnet.bscscan.com/tx/' + t.tx_id"
                    target="_blink"
                    ><i class="fad fa-link mr-1"></i>
                    {{ t.tx_id.slice(0, 4) }}...{{
                      t.tx_id.slice(t.tx_id.length - 4)
                    }}
                  </a>
                </div>

                <div class="text-sm sm:text-base">
                  {{ t.remark }},
                  <span class="text-xs"
                    >fee: {{ fixed(t.fee) }} {{ t.currency }}</span
                  >
                </div>
              </div>
              <div
                class="flex-none text-right flex flex-col sm:flex-row text-sm sm:text-base"
              >
                <div>
                  {{ fixed(t.amount) }}
                  <img
                    class="inline-block h-4 ml-2"
                    :src="getCurrency[t.currency].logo"
                  />
                </div>

                <div
                  v-bind:class="
                    'text-xs sm:inline-block text-center font-semibold mt-1 py-1 rounded uppercase ml-10 sm:ml-2 w-24 ' +
                    t.label
                  "
                >
                  {{ t.type }}
                </div>
              </div>
            </div>

            <div class="text-center">
              <div
                class="text-sm mt-2 px-6 py-2 border rounded text-yellow-50 hover:bg-gray-500 hover:bg-opacity-25 border-none cursor-pointer"
                v-on:click="getdata()"
                v-if="!nomore"
              >
                Load more...
              </div>
              <div
                class="text-sm mt-2 px-6 py-2 border rounded text-yellow-50 border-none"
                v-else
              >
                - No more -
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import chameleon from "@/assets/svg/chameleon.vue";
import loader from "@/assets/svg/loader.vue";
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import info from "@/api/info";
import func from "@/widgets/func";
import label from "@/widgets/label";
import moment from "moment";
import nativeToast from "native-toast";

export default {
  components: {
    loader,
    chameleon,
    ConnectWallet,
    StickFooter,
  },
  data() {
    return {
      search: "",
      address:
        this.$route.params.address ||
        "0xcd4649e89e05f43f0d329d3d9c2e7ddd9d645b34",
      ready: false,
      page: 0,
      nomore: false,
      table_rows: [],
      expanded: null,
    };
  },
  computed: {
    ...mapGetters(["getCurrency"]),
    filteredItems() {
      return this.table_rows.filter((item) => {
        return (
          item.type.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.currency.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.remark.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.tx_id.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },

  methods: {
    fixed(a) {
      // return func.fixed(a);

      return parseFloat(a).toFixed(3);
    },
    expand(id) {
      if (this.expanded == id) {
        this.expanded = null;
        return;
      }
      this.expanded = id;
    },
    getdata() {
      info
        .usertxbet({ page: this.page, address: this.address })
        .then((res) => {
          this.ready = true;
          if (res.data.length == 0) {
            this.nomore = true;
          } else {
            res.data.forEach((e) => {
              e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

              var a = label.type(e.reward);
              e.label = a.label;

              this.table_rows.push(e);
            });
            this.page = this.page + 1;
          }
        })
        .catch((error) => {
          nativeToast({
            message: error.response.data.error,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
  },
  created() {
    this.getdata();
  },
};
</script>
